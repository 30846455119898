/* tslint:disable */
/* eslint-disable */
/**
 * GloinService
 * A generic API for internal serverless applications
 *
 * The version of the OpenAPI document: 2022-04-25 23:24:37UTC
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'pk'?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'sk'?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'campaignName': string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'campaignId': string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'campaignDesc': string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'recordType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'campaignStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'numberOfTickets'?: string;
    /**
     * last updated date and time
     * @type {string}
     * @memberof Campaign
     */
    'lastUpdatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}

/**
 * CampaignsApi - axios parameter creator
 * @export
 */
export const CampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete campaign
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {Campaign} [campaign] Adding/updating campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign: async (campaignid: string, campaign?: Campaign, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignid' is not null or undefined
            assertParamExists('deleteCampaign', 'campaignid', campaignid)
            const localVarPath = `/jwt/campaigns/{campaignid}`
                .replace(`{${"campaignid"}}`, encodeURIComponent(String(campaignid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MidwayAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign: async (campaignid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignid' is not null or undefined
            assertParamExists('getCampaign', 'campaignid', campaignid)
            const localVarPath = `/jwt/campaigns/{campaignid}`
                .replace(`{${"campaignid"}}`, encodeURIComponent(String(campaignid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MidwayAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all campaigns
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaigns: async (limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/jwt/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MidwayAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary put campaign
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {Campaign} [campaign] Adding/updating campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCampaign: async (campaignid: string, campaign?: Campaign, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignid' is not null or undefined
            assertParamExists('putCampaign', 'campaignid', campaignid)
            const localVarPath = `/jwt/campaigns/{campaignid}`
                .replace(`{${"campaignid"}}`, encodeURIComponent(String(campaignid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MidwayAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignsApi - functional programming interface
 * @export
 */
export const CampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete campaign
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {Campaign} [campaign] Adding/updating campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCampaign(campaignid: string, campaign?: Campaign, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCampaign(campaignid, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaign(campaignid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaign(campaignid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all campaigns
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCampaigns(limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Campaign>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCampaigns(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary put campaign
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {Campaign} [campaign] Adding/updating campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCampaign(campaignid: string, campaign?: Campaign, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCampaign(campaignid, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignsApi - factory interface
 * @export
 */
export const CampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignsApiFp(configuration)
    return {
        /**
         * 
         * @summary delete campaign
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {Campaign} [campaign] Adding/updating campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign(campaignid: string, campaign?: Campaign, options?: any): AxiosPromise<Campaign> {
            return localVarFp.deleteCampaign(campaignid, campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign(campaignid: string, options?: any): AxiosPromise<Campaign> {
            return localVarFp.getCampaign(campaignid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all campaigns
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaigns(limit?: number, options?: any): AxiosPromise<Array<Campaign>> {
            return localVarFp.listCampaigns(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary put campaign
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {Campaign} [campaign] Adding/updating campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCampaign(campaignid: string, campaign?: Campaign, options?: any): AxiosPromise<Campaign> {
            return localVarFp.putCampaign(campaignid, campaign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignsApi - object-oriented interface
 * @export
 * @class CampaignsApi
 * @extends {BaseAPI}
 */
export class CampaignsApi extends BaseAPI {
    /**
     * 
     * @summary delete campaign
     * @param {string} campaignid Generated path parameter for campaignid
     * @param {Campaign} [campaign] Adding/updating campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public deleteCampaign(campaignid: string, campaign?: Campaign, options?: AxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).deleteCampaign(campaignid, campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignid Generated path parameter for campaignid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getCampaign(campaignid: string, options?: AxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).getCampaign(campaignid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all campaigns
     * @param {number} [limit] How many items to return at one time (max 100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public listCampaigns(limit?: number, options?: AxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).listCampaigns(limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary put campaign
     * @param {string} campaignid Generated path parameter for campaignid
     * @param {Campaign} [campaign] Adding/updating campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public putCampaign(campaignid: string, campaign?: Campaign, options?: AxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).putCampaign(campaignid, campaign, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwtCampaignsCampaignidTicketsGet: async (campaignid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignid' is not null or undefined
            assertParamExists('jwtCampaignsCampaignidTicketsGet', 'campaignid', campaignid)
            const localVarPath = `/jwt/campaigns/{campaignid}/tickets`
                .replace(`{${"campaignid"}}`, encodeURIComponent(String(campaignid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MidwayAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {string} ticketId Generated path parameter for ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwtCampaignsCampaignidTicketsTicketIdGet: async (campaignid: string, ticketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignid' is not null or undefined
            assertParamExists('jwtCampaignsCampaignidTicketsTicketIdGet', 'campaignid', campaignid)
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('jwtCampaignsCampaignidTicketsTicketIdGet', 'ticketId', ticketId)
            const localVarPath = `/jwt/campaigns/{campaignid}/tickets/{ticketId}`
                .replace(`{${"campaignid"}}`, encodeURIComponent(String(campaignid)))
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MidwayAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {string} ticketId Generated path parameter for ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwtCampaignsCampaignidTicketsTicketIdPost: async (campaignid: string, ticketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignid' is not null or undefined
            assertParamExists('jwtCampaignsCampaignidTicketsTicketIdPost', 'campaignid', campaignid)
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('jwtCampaignsCampaignidTicketsTicketIdPost', 'ticketId', ticketId)
            const localVarPath = `/jwt/campaigns/{campaignid}/tickets/{ticketId}`
                .replace(`{${"campaignid"}}`, encodeURIComponent(String(campaignid)))
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MidwayAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jwtCampaignsCampaignidTicketsGet(campaignid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jwtCampaignsCampaignidTicketsGet(campaignid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {string} ticketId Generated path parameter for ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jwtCampaignsCampaignidTicketsTicketIdGet(campaignid: string, ticketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jwtCampaignsCampaignidTicketsTicketIdGet(campaignid, ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {string} ticketId Generated path parameter for ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jwtCampaignsCampaignidTicketsTicketIdPost(campaignid: string, ticketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jwtCampaignsCampaignidTicketsTicketIdPost(campaignid, ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwtCampaignsCampaignidTicketsGet(campaignid: string, options?: any): AxiosPromise<void> {
            return localVarFp.jwtCampaignsCampaignidTicketsGet(campaignid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {string} ticketId Generated path parameter for ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwtCampaignsCampaignidTicketsTicketIdGet(campaignid: string, ticketId: string, options?: any): AxiosPromise<void> {
            return localVarFp.jwtCampaignsCampaignidTicketsTicketIdGet(campaignid, ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignid Generated path parameter for campaignid
         * @param {string} ticketId Generated path parameter for ticketId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwtCampaignsCampaignidTicketsTicketIdPost(campaignid: string, ticketId: string, options?: any): AxiosPromise<void> {
            return localVarFp.jwtCampaignsCampaignidTicketsTicketIdPost(campaignid, ticketId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {string} campaignid Generated path parameter for campaignid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public jwtCampaignsCampaignidTicketsGet(campaignid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).jwtCampaignsCampaignidTicketsGet(campaignid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignid Generated path parameter for campaignid
     * @param {string} ticketId Generated path parameter for ticketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public jwtCampaignsCampaignidTicketsTicketIdGet(campaignid: string, ticketId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).jwtCampaignsCampaignidTicketsTicketIdGet(campaignid, ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignid Generated path parameter for campaignid
     * @param {string} ticketId Generated path parameter for ticketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public jwtCampaignsCampaignidTicketsTicketIdPost(campaignid: string, ticketId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).jwtCampaignsCampaignidTicketsTicketIdPost(campaignid, ticketId, options).then((request) => request(this.axios, this.basePath));
    }
}


