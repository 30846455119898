import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import DetailsTable from '../table/Table';

// The content in the main content area of the App layout
export default function HomepageContent() {
  return (
    <>
      <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 10, l: 9, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } }
          ]}
        >
          <SpaceBetween size="l">
            <div>
              <Header variant="h1" headingTagOverride="h2">
                Campaigns
              </Header>
              <Container>
                <DetailsTable/>
              </Container>
            </div>
          </SpaceBetween>
        </Grid>
      </Box>
    </>
  );
}
