import { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import CampaignsApiFactory from '../../gloin-api/CampaignsApiFactory';
import { Campaign } from '../../gloin-api/generated-src/index';

export default function FormPage() {
  const history = useHistory();
  const {id, mode} = useParams<{id: string, mode: string}>();
  const campaignId = (id);
  const initialCampaignData: Campaign = {
    campaignName: 'test',
    campaignDesc: 'test',
    createdBy:'sri',
    campaignId:'1'
  };
  const [campaignData, setCampaignData] = useState(initialCampaignData);
  const CampaignApi = CampaignsApiFactory();

  if (mode === 'edit') {
    useEffect(() => {
      (async () => {
        setCampaignData((await CampaignApi.getCampaign(campaignId)).data);
      })();
    }, []);
  }

  const onChange = (attribute, value) => {
    const newState = { ...campaignData };
    newState[attribute] = value;
    setCampaignData(newState);
  };

  async function save() {
    await CampaignApi.putCampaign(campaignId,campaignData);
    history.push('/');
  }

  return (
    <>  
      <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 6, l: 6, s: 6, xxs: 8 }, offset: { s: 3, xxs: 1 } }
          ]}
        >
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" href="#/">Cancel</Button>
                <Button variant="primary" onClick={save}>Submit</Button>
              </SpaceBetween>
            }
            header={
              <Header variant="h1" description="Campaign information">
                Campaign
              </Header>
            }
          >
            <Container >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Id" stretch>
                  <Input
                    value={campaignData.campaignId}
                    ariaRequired={true}
                    placeholder="Campaign id"
                    disabled={mode === 'edit'}
                    onChange={({ detail: { value } }) => onChange('id', value)}
                  />
                </FormField>
                <FormField label="Name">
                  <Input
                    value={campaignData.campaignName}
                    ariaRequired={true}
                    placeholder="Campaign Name"
                    onChange={({ detail: { value } }) => onChange('name', value)}
                  />
                </FormField>
                <FormField label="Description">
                  <Input
                    value={campaignData.campaignDesc || ''}
                    ariaRequired={true}
                    placeholder="Description"
                    onChange={({ detail: { value } }) => onChange('description', value)}
                  />
                </FormField>
                <FormField label="CreatedBy">
                  <Input
                      value={campaignData.createdBy || ''}
                      ariaRequired={true}
                      placeholder="your amazon alias"
                      onChange={({ detail: { value } }) => onChange('createdBy', value)}
                  />
                </FormField>
                <FormField label="RecordType">
                  <Input
                      value={campaignData.recordType || ''}
                      ariaRequired={true}
                      placeholder="recordType"
                      onChange={({ detail: { value } }) => onChange('recordType', value)}
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          </Form>
        </Grid>
      </Box>
    </>
  );
}
